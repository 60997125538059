<template>
    <layout-cabinet
        :online-mode="true"
    >
        <template slot="content">
            <router-view />
        </template>
    </layout-cabinet>
</template>

<script>
import LayoutCabinet from '@/views/layouts/LayoutCabinet';

export default {
    name: 'OnlineIndex',
    components: {
        LayoutCabinet
    },
    created() {
        this.$onlineLkAccessManager.autoLoginUser(true);
    }
};
</script>

<style scoped>

</style>
